





















































































/* eslint-disable @typescript-eslint/no-explicit-any */
import Vue from "vue";

import { mapGetters, mapState } from "vuex";
import BtnMakeCall from "../buttons/BtnMakeCall.vue";

export default Vue.extend({
  name: "secondary-toolbar",
  data() {
    return {
      isAdminT: false,
    };
  },

  inject: ["callCenterProvider"],
  components: { BtnMakeCall },
  computed: {
    ...mapState("crmCallCenterModule", ["onlineWorkers"]),
    ...mapGetters([
      "isSuper",
      "isAdmin",
      "isWorker",
      "isDoctor",
      "getMethodCalls",
    ]),

    badgeWorkersCount(): string | number {
      const workersCount = this.onlineWorkers.length;

      if (workersCount > 0 && workersCount <= 9) {
        return workersCount;
      } else if (workersCount == 0) {
        return "";
      }

      return "9+";
    },
  },
  methods: {
    showSidebarCallInProgress() {
      (this as any).callCenterProvider.showSidebarOnlineUsers = false;
      (this as any).callCenterProvider.showSidebarCallInProgress = !(
        this as any
      ).callCenterProvider.showSidebarCallInProgress;
    },
    showSidebarOnlineUsers() {
      (this as any).callCenterProvider.showSidebarCallInProgress = false;
      (this as any).callCenterProvider.showSidebarOnlineUsers = !(this as any)
        .callCenterProvider.showSidebarOnlineUsers;
    },
  },
  mounted() {
    if (this.isAdmin || this.isSuper) {
      this.isAdminT = true;
    }
  },
});
