












/* eslint-disable @typescript-eslint/no-explicit-any */
import Vue from "vue";
import { mapActions } from "vuex";
// import AsideCall from "./Layout/AsideCall.vue";
import SecondaryToolbar from "./Layout/SecondaryToolbar.vue";
// import { OnlineWorkers } from "@/components/layout";

export default Vue.extend({
  name: "crm-call-center",
  inject: ["callCenterProvider"],
  components: {
    SecondaryToolbar,
    // AsideCall,
    // OnlineWorkers,
  },

  async mounted() {
    if (
      this.$store.getters.loggedIn &&
      (this.$store.getters.isWorker ||
        this.$store.getters.isAdmin ||
        this.$store.getters.isSupervisor ||
        this.$store.getters.isSuper)
    ) {
      setTimeout(() => {
        (this as any).$root.initDevice();
      }, 1000);
    }

    (this as any).$parent.$parent.$parent.topBar = true;

    if (this.$vuetify.breakpoint.mobile == false) {
      (this as any).$parent.$parent.$parent.drawer = true;
    }
  },
});
