



















































































































/* eslint-disable @typescript-eslint/no-explicit-any */
import Vue from "vue";
import Dialer from "@/components/Dialer/Dialer.vue";
import CallInProgress from "../CallInProgress.vue";
import {
  notifyError,
  notifyInfo,
  notifySuccess,
} from "@/components/Notification";
import { getAPI } from "@/api/axios-base";
import { mapGetters, mapState } from "vuex";

export default Vue.extend({
  name: "btn-make-call",
  props: {
    external: Boolean,
    numberPatient: String,
    lead: String,
    fromcall: Boolean,
  },
  components: {
    Dialer,
    CallInProgress,
  },
  data() {
    return {
      showPopover: false,
      callUi: 0,
      number: "",
      leadUuid: "",
      message: "",
    };
  },
  computed: {
    ...mapGetters({ methodCall: "getMethodCall" }),

    colors() {
      switch (this.message) {
        case "callInProgress":
          return "green";

        case "errorcall":
          return "red";

        default:
          return "blue";
      }
    },
  },
  methods: {
    makeCall(number: string) {
      if (this.numberPatient == undefined && number == undefined) {
        this.callUi = 0;
      } else {
        this.callUi = 1;
        this.number = number;
        if (this.lead != undefined && this.lead != null && this.lead != "") {
          this.leadUuid = this.lead;
        }
      }
    },
    errorcaller(error: any) {
      this.number = "";
      this.callUi = 0;
      this.showPopover = false;
      let mess = error.response.data.message;

      if (mess.includes("[")) {
        mess = mess.replace("[", "");
        mess = mess.replace("]", "");
      }
      notifyError(error.response.data, `An error occurred: ${mess}`);
    },
    hangup() {
      this.number = "";
      this.callUi = 0;
      this.showPopover = false;
      notifyInfo(this.$t("crmCallCenter.notificationCallFinish") as string);
    },
  },
});
